.widgetsList {
    padding-bottom: 32px;
}

.widget {}

.widgetSubtitle {
    font-size: 12px;
    color: var(--ion-color-medium-shade);
    text-transform: uppercase;
    font-weight: 600;
    letter-spacing: .07rem;
}

.widgetMeal .button,
.widgetGrocery .button {
    text-transform: uppercase;
    position: relative;
}

.widgetMeal .button .icon,
.widgetGrocery .button .icon {
    position: absolute;
    top: 2px;
    margin-left: 4px;
}

.widgetMeal, .widgetGrocery {
    border-radius: 0;
    box-shadow: 0 1px 2px rgba(165,176,202,0.07), 
                0 2px 4px rgba(165,176,202,0.07), 
                0 4px 8px rgba(165,176,202,0.07), 
                0 8px 16px rgba(165,176,202,0.07), 
                0 16px 32px rgba(165,176,202,0.07);
}

.widgetMeal {
    border-radius: 0;
}

.widgetMeal .coverImg {
    display: block;
    background-size: cover;
    background-repeat: no-repeat;
    height: 300px;
}

.widgetMeal .fab {
    bottom: 88px;
    right: 16px;
}

.widgetMeal ion-card-title {
    margin-bottom: 8px;
}

.widgetMeal ion-card-title a {
    color: #313131;
}

/* Desktop devices */
:global(.plt-desktop) .widgetMeal .coverImg {
    height: 440px;
}