.contentWrapper {
    --padding-start: 24px;
    --padding-end: 24px;
}

.contentWrapper header {
    text-align: center;
    padding-top: 16px;
    padding-bottom: 16px;
}

.headerCta {
    text-decoration: underline;
}

.formInputGroup, .formGroup {
    display: block;
    margin-bottom: 16px;
}

.formInputGroup {
    --background: #f8f8f8;
    --border-radius: 4px;
}

.formGroup.checkBox {
    display: flex;
}

.formActions {
    display: block;
    margin-top: 40px;
}

.spinnerContainer {
    display: block;
    text-align: center;
}

.toast {
    --start: 24px;
    --end: 24px;
}

.formLink {
    display: block;
    font-size: 14px;
    margin-top: 32px;
}

/* Desktop */
:global(.plt-desktop) .contentWrapper {
    --background: #fff;
}