.segmentStore {}

.previewContent {
    --padding-bottom: 56px;
}

.cardChip {
    border: 0;
    margin-top: -6px;
}

.pageDesc {
    padding-left: 24px;
    padding-right: 24px;
}