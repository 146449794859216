.card {
    display: flex;
    flex-direction: row;
    margin-left: 16px;
    margin-right: 16px;
    margin-bottom: 8px;
    background-color: #fff;
    box-shadow: 0 1px 2px rgba(165,176,202,0.07), 
                0 2px 4px rgba(165,176,202,0.07), 
                0 4px 8px rgba(165,176,202,0.07), 
                0 8px 16px rgba(165,176,202,0.07), 
                0 16px 32px rgba(165,176,202,0.07);
}

.card .thumb {
    flex: 0 0 80px;
    width: 80px;
    height: 80px;
    margin: 8px;
    margin-right: 16px;
    position: relative;
}

.card .lockOverlay {
    position: absolute;
    width: 100%;
    height: 100%;
}

.card .lockOverlay ion-icon {
    color: var(--ion-color-primary-contrast);
    margin: 0;
    margin-left: 40%;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
}

.card .tagBanner {
    color: var(--ion-color-light);
    font-size: 14px;
    /*background-color: rgba(201, 188, 162, 0.6);*/
    background-color: var(--ion-color-primary);
    padding: 4px;
    position: absolute;
    width: 24px;
    height: 24px;
    bottom: 0;
    right: 0;
    opacity: .8;
}

.card .tagBanner ion-icon {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
}

.iconText {
    display: inline-block;
    margin-right: 4px;
}

.iconText ion-icon {
    float: left;
    margin-top: 1px;
    margin-right: -1px;
}

.veganBadge {
    color: #4a9009;
}

.vyIonLabel {
    overflow: hidden;
    width: 100%;
    margin: 8px;
    margin-left: 0;
}

.vyIonLabel .labelInfo {
    font-size: 12px;
    color: var(--ion-color-medium-shade);
    text-transform: uppercase;
    font-weight: 600;
    letter-spacing: .07rem;
}

.vyIonLabel .title {
    font-size: 16px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.vyIonLabel .footer {
    display: flex;
    align-content: center;
    justify-content: space-between;
    margin-top: 4px;
    margin-left: -12px;
}

.vyIonLabel .footer ion-button {
    font-size: 12px;
    font-weight: 600;
}

.vyIonLabel .footer .labelInfo {
    color: var(--ion-color-gold);
    font-size: 10px;
    padding-top: 11px;
    padding-bottom: 11px;
}

.labelInfo.low {
    color: #2ABC69 !important;
}

.labelInfo.medium {
    color: #159CDE !important;
}

.labelInfo.high {
    color: #0376B8 !important;
}

.subhead {
    display: flex;
    flex-grow: 1;
    justify-content: space-between;
    font-family: basic-sans, sans-serif;
    font-size: 12px;
    font-weight: 600;
    color: var(--ion-color-medium-shade);
    text-transform: uppercase;
}

.subhead div.labelInfo > .iconText:last-child {
    margin-right: 0;
}