.stickyAction {
    display: block;
    position: absolute;
    background-color: #fff;
    padding: 16px;
    bottom: 0;
    width: 100%;
    box-shadow: 
        0 -1px 2px rgba(165,176,202,0.07), 
        0 -2px 4px rgba(165,176,202,0.07), 
        0 -4px 8px rgba(165,176,202,0.07), 
        0 -8px 16px rgba(165,176,202,0.07), 
        0 -16px 32px rgba(165,176,202,0.07);
}