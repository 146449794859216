.banner {
    display: block;
    /*color: #fff;*/
    color: var(--ion-color-dark-tint);
    text-align: center;
    background: #F6D2B6;
    background: linear-gradient(#ffffff, #ffeddf);
    border: 1px solid #FF7F3C;
    border-radius: 4px;
    padding: 16px;
    box-shadow: 0 1px 2px rgba(165,176,202,0.07), 
                0 2px 4px rgba(165,176,202,0.07), 
                0 4px 8px rgba(165,176,202,0.07), 
                0 8px 16px rgba(165,176,202,0.07), 
                0 16px 32px rgba(165,176,202,0.07);
}

.banner.margin {
    margin: 16px;
}

.banner .subheader {
    color: #BA3815;
    text-transform: uppercase;
    font-size: 14px;
    letter-spacing: 0.6px;
    /*opacity: .6;*/
}

.banner p {
    /*color: #fff;*/
    color: var(--ion-color-dark-tint);
    font-size: 18px;
    line-height: 32px;
}

.banner .highlight {
    color: var(--ion-color-dark-tint);
    font-weight: bold;
    /*background-color: #BA3815;*/
}

.banner button {
    display: block;
    outline: 0;
    /*color: var(--ion-color-dark-tint);*/
    color: #fff;
    font-size: 18px;
    text-transform: uppercase;
    text-decoration: none;
    background-color: #BA3815;
    width: 100%;
    padding: 16px;
}

.banner button:focus {
    background-color: var(--ion-color-warning-shade);
}