.container {
    display: block;
    text-align: center;
    height: 100vh;
}

.verticalAlign {
    display: block;
    position: absolute;
    top: calc(50% - 148px);
    width: 100%;
    height: 160px;
    padding: 24px;
    opacity: .6;
}

.icon {
    font-size: 24px;
}