.widget {

}

.widget ion-content {
    --padding-top: 24px;
    --padding-start: 24px;
    --padding-end: 24px;
    --padding-bottom: 24px;
}

.widget ion-content ion-toolbar {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    --padding-start: 24px;
    --padding-end: 24px;
    --padding-bottom: 16px;
}

.widget ion-textarea {
    --background: #f8f8f8;
    border-radius: 4px;
    border-bottom: 2px solid var(--ion-color-light-shade);
    padding: 8px 16px;
}

.widget ion-textarea.activeTextbox {
    border-color: var(--ion-color-primary);
}

.widget form {
    display: block;
}