.dayplanList {
    background-color: transparent;
}

.dayplanHeader {
    color: var(--ion-color-dark-tint);
}

.calBtn {
    margin-right: 16px;
}

.calBtn ion-icon {
    float: left;
    margin-top: 2px;
    margin-right: 4px;
}

.actionsWrapper {
    display: block;
    padding-top: 16px;
    padding-left: 16px;
    padding-right: 16px;
}

.addMealBtn {
    display: block;
    color: var(--ion-color-secondary-shade);
    font-size: 14px;
    background-color: #fff;
    border: 1px dashed var(--ion-color-secondary);
    padding: 16px;
    width: 100%;
}

.emptyDayPlacelholder {
    display: block;
    text-align: center;
}

.emptyDayPlacelholder span {
    color: var(--ion-color-medium-shade);
}