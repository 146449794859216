@import url("https://use.typekit.net/dzw0dtc.css");
@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro&display=swap');

:root {
  --ion-font-family: 'Source Sans Pro', Arial, sans-serif;

  /* COLOR SCHEME */
  --ion-color-primary: #235536;
  --ion-color-primary-rgb: 35,85,54;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255,255,255;
  --ion-color-primary-shade: #1f4b30;
  --ion-color-primary-tint: #39664a;

  --ion-color-secondary: #C3B498;
  --ion-color-secondary-rgb: 195,180,152;
  --ion-color-secondary-contrast: #000000;
  --ion-color-secondary-contrast-rgb: 0,0,0;
  --ion-color-secondary-shade: #ac9e86;
  --ion-color-secondary-tint: #c9bca2;

  --ion-color-gold: #cea668;

  --ion-color-tertiary: #589077;
  --ion-color-tertiary-rgb: 88,144,119;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255,255,255;
  --ion-color-tertiary-shade: #4d7f69;
  --ion-color-tertiary-tint: #699b85;

  --ion-color-success: #54A40A;
  --ion-color-success-rgb: 84,164,10;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255,255,255;
  --ion-color-success-shade: #4a9009;
  --ion-color-success-tint: #65ad23;

  --ion-color-warning: #F8C01C;
  --ion-color-warning-rgb: 248,192,28;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0,0,0;
  --ion-color-warning-shade: #daa919;
  --ion-color-warning-tint: #f9c633;

  --ion-color-danger: #D20606;
  --ion-color-danger-rgb: 210,6,6;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255,255,255;
  --ion-color-danger-shade: #b90505;
  --ion-color-danger-tint: #d71f1f;

  --ion-color-dark: #35342D;
  --ion-color-dark-rgb: 53,52,45;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255,255,255;
  --ion-color-dark-shade: #2f2e28;
  --ion-color-dark-tint: #494842;

  --ion-color-medium: #A5B0CA;
  --ion-color-medium-rgb: 165,176,202;
  --ion-color-medium-contrast: #000000;
  --ion-color-medium-contrast-rgb: 0,0,0;
  --ion-color-medium-shade: #919bb2;
  --ion-color-medium-tint: #aeb8cf;

  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244,244,244;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0,0,0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;

  --app-desktop-background: #f8f8f8;
}

/**
 * Typography
 */
body {
  color: var(--ion-color-dark);
  font-family: 'Source Sans Pro', Arial, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 16px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #313131;
  font-family: basic-sans, sans-serif;
  font-weight: 600;
  font-style: normal;
}

h1 {
  line-height: 2.2rem;
}

p, ul, ul li {
  color: var(--ion-color-dark-tint);
  font-family: 'Source Sans Pro', Arial, sans-serif;
  font-weight: 400;
  font-style: normal;
  letter-spacing: 0.3px;
}

.caption {
  color: var(--ion-color-dark-tint);
  font-size: 14px;
  letter-spacing: 0.4px;
}

a {
  text-decoration: none;
}

/** 
 * Ionic customisations
 */
ion-button {
  --box-shadow: none;
  --border-radius: 18px;
  --padding-bottom: 8px;
  --padding-end: 16px;
  --padding-start: 16px;
  --padding-top: 8px;
  font-size: 14px;
}

ion-button.lg {
  --padding-bottom: 16px;
  --padding-top: 16px;
}

ion-header::after {
  background-image: none !important;
  bottom: 0px !important;
  box-shadow: 0 1px 2px rgba(165,176,202,0.07), 
              0 2px 4px rgba(165,176,202,0.07), 
              0 4px 8px rgba(165,176,202,0.07), 
              0 8px 16px rgba(165,176,202,0.07), 
              0 16px 32px rgba(165,176,202,0.07);
}

ion-card {
  margin-left: 16px;
  margin-right: 16px;
}

ion-label {
  letter-spacing: 0.3px;
}

/**
 * Global styles
 */
ion-header.no-shadow::after {
  background-image: none;
}

.subtitle {
  font-size: 12px;
  color: var(--ion-color-medium-shade);
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: .07rem;
}

@media (min-width: 1025px) {
  /*
  .plt-desktop ion-content {
    --background: var(--app-desktop-background);
  }
  */
}