.card {
    border-radius: 0;
    box-shadow: 0 1px 2px rgba(165,176,202,0.07), 
                0 2px 4px rgba(165,176,202,0.07), 
                0 4px 8px rgba(165,176,202,0.07), 
                0 8px 16px rgba(165,176,202,0.07), 
                0 16px 32px rgba(165,176,202,0.07);
}

.card .content {
    overflow: hidden;
}

.card .title {
    display: flex;
}

.date {
    display: block;
    float: left;
}

.description,
.actions {
    display: block;
    width: 100%;
    overflow: hidden;
}

.newChip {
    font-size: 12px;
    font-weight: bold;
    text-transform: uppercase;
    height: 20px;
    margin: 2px;
}

.button {
    font-size: 14px;
    --background-activated: transparent;
    --background-hover: transparent;
    --padding-start: 0;
    --padding-top: 0;
    --padding-bottom: 0;
}

.button .icon {
    font-size: 13px;
    margin-left: 4px;
}
