.contentWrapper {
    --padding-start: 16px;
    --padding-end: 16px;
    --padding-bottom: 140px; /* allow scrolling gap for sticky checkout */
}

.contentWrapper footer {
    display: block;
    text-align: center;
    margin-top: 8px;
}

.contentWrapper footer .disclaimer {
    display: block;
    margin-bottom: 8px;
}

.benefits ion-item {
    --padding-top: 8px;
    --padding-bottom: 8px;
}

.benefits ion-icon,
.otherBenefits ion-icon {
    color: var(--ion-color-primary);
    margin-right: 8px;
}

.benefits ion-label,
.otherBenefits ion-label {
    font-size: 14px;
    white-space: normal !important;
}

.otherBenefits {
    margin-top: 24px;
}

.otherBenefits h6 {
    color: var(--ion-color-dark-tint);
}

.passSelector h6 {
    margin-bottom: 16px;
}

.productSelection {
    display: block;
    background: #fff;
    background: linear-gradient(#fff, var(--ion-color-light));
    border: 1px solid var(--ion-color-medium-tint);
    border-radius: 4px;
    width: 100%;
    padding: 16px;
    margin-bottom: 8px;
}

.productSelection p {
    display: block;
    color: var(--ion-color-dark-tint);
    font-size: 18px;
    margin: 0;
    margin-bottom: 8px;
}

.productSelection p.subline {
    font-size: 14px;
    opacity: .8;
    margin-bottom: 0;
}

.productSelection ion-icon {
    color: var(--ion-color-primary);
    font-size: 24px;
    margin-bottom: 16px;
}

.productSelection.selected {
    border: 2px solid var(--ion-color-primary);
    box-shadow: 0 1px 2px rgba(165,176,202,0.07), 
                0 2px 4px rgba(165,176,202,0.07), 
                0 4px 8px rgba(165,176,202,0.07), 
                0 8px 16px rgba(165,176,202,0.07), 
                0 16px 32px rgba(165,176,202,0.07);
}

.stickyCheckout {
    display: block;
    position: absolute;
    z-index: 999;
    background-color: #fff;
    padding: 16px;
    bottom: 0;
    width: 100%;
    box-shadow: 
        0 -1px 2px rgba(165,176,202,0.07), 
        0 -2px 4px rgba(165,176,202,0.07), 
        0 -4px 8px rgba(165,176,202,0.07), 
        0 -8px 16px rgba(165,176,202,0.07), 
        0 -16px 32px rgba(165,176,202,0.07);
}

.strikeText {
    text-decoration: line-through;
    color: #BA3815;
}

.saleBanner {
    padding-left: 22px;
    margin-bottom: 8px;
}

.sale-banner ion-item {
    --background: transparent;
}

.saleBanner ion-icon,
.saleBanner ion-label {
    color: /*var(--ion-color-warning);*/ #BA3815;
}

.saleBanner ion-icon {
    margin-right: 8px;
}

.saleBanner ion-label {
    white-space: normal !important;
}

@media (min-width: 1025px) {
    .stickyCheckout {
        background-color: var(--app-desktop-background);
    }
}