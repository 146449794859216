.headSection {
    border-bottom: 1px solid var(--ion-color-light);
    padding-top: 8px;
    padding-bottom: 8px;
}

.subheader {
    text-align: center;
}

.subheader p {
    line-height: 8px;
}

.disclaimer p {
    display: block;
    text-align: center;
    padding-left: 16px;
    padding-right: 16px;
}

.contentSection {
    padding: 16px;
}

.contentSection ul {
    padding: 0;
    margin: 0;
}

.contentSection li {
    list-style: none;
    padding: 0;
    margin: 0;
}

li.nutritionItem {
    margin-bottom: 16px;
}

.nutritionItem > span {
    display: flex;
    justify-content: space-between;
}

.progressBar {
    display: block;
    width: 100%;
	overflow: hidden;
}

.progressBar span {
    display: block;
}

.progressBar .bar {
    /*background: var(--ion-color-light-tint);*/
    background: #EAF0ED;
}

.progressBar .progress {
    animation: loader 1s ease-out forwards;
    background: var(--ion-color-primary);
    color: #fff;
    padding: 1px; /* bar size */
    width: 0;
}

@keyframes loader {
    0% { width: 0 };
}