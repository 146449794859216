.backdropContent {
    --background: #235536;
}

.backdropBackLayer {
}

.backdropFrontLayer {
    display: block;
    background-color: #fff;
    border-top-left-radius: 24px;
    border-top-right-radius: 24px;
    height: 100%;
    padding-bottom: 24px;
    overflow-y: hidden;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 999;
    transition: top .3s ease-in-out;
}

.backdropFrontLayer header {
    display: block;
    padding: 16px;
    padding-bottom: 8px;
}

.backdropFrontLayer .title {
    font-size: 16px;
    color: #235536;
    letter-spacing: 0.2px;
    line-height: 28px;
    margin: 0;
}

.backdropFrontLayer .description {
    font-size: 12px;
    letter-spacing: 0.1px;
    line-height: 20px;
    margin: 0;
}

.backdropFrontLayer .content {
    display: block;
    overflow-y: scroll;
    height: 100%;
    padding-bottom: 56px;
}