.widgetCalories {
    padding-top: 32px;
    padding-bottom: 32px;
    padding-top: 24px;
    padding-bottom: 8px;
}

.widgetCalories .actionsBar {
    display: block;
    text-align: center;
    border-bottom: 1px solid var(--ion-color-light);
    padding-top: 16px;
    padding-bottom: 16px;
}

.widgetCalories .actionsBar ion-button {
    --padding-start: 40px;
    --padding-end: 40px;
}