.contentWrapper {
    --padding-start: 24px;
    --padding-end: 24px;
}

.contentWrapper header {
    text-align: center;
    padding-top: 32px;
    padding-bottom: 16px;
}

.logo {
    display: block;
    background-color: #f8f8f8;
    border-radius: 4px;
    width: 72px;
    height: 72px;
    padding: 16px;
    margin: 0 auto;
    margin-bottom: 32px;
    box-shadow: -1px 1px 1px rgba(165,176,202,0.2), 
                -2px 2px 2px rgba(165,176,202,0.2);
}

.logo span {
    display: block;
    background-image: url('../assets/app-icon-logo.svg');
    background-position: center center;
    background-size: contain;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
}

.headerCta {
    text-decoration: underline;
}

.formInputGroup, .formGroup {
    display: block;
    margin-bottom: 16px;
}

.formInputGroup {
    --background: #f8f8f8;
    --border-radius: 4px;
}

.formGroup.checkBox {
    display: flex;
}

.formActions {
    display: block;
    margin-top: 40px;
}

.spinnerContainer {
    display: block;
    text-align: center;
}

.terms .label {
    color: var(--ion-color-dark-tint);
    font-size: 14px;
    white-space: normal;
}

.terms .label.checkbox {
    margin-left: 16px;
}

.terms .label.center {
    text-align: center;
}

.warningToast {
    --start: 24px;
    --end: 24px;
}

.resetLink {
    font-size: 14px;
}

/* Desktop */
:global(.plt-desktop) .contentWrapper {
    --background: #fff;
}