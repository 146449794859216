.wrapper {
    display: block;
    text-align: center;
    padding-top: 56px;
}

.message {
    display: block;
    margin-top: 24px;
    margin-bottom: 40px;
}