.groceryToolbar {
    display: flex;
    justify-content: space-between;
    background-color: #fff;
    padding-left: 20px;
    padding-right: 8px;
    padding-bottom: 8px;
}

.groceryToolbar .servingsSelector {
    display: flex;
}

.groceryToolbar .servingsSelector ion-label {
    padding-top: 12px;
    padding-bottom: 12px;
}

.listHeader {
    min-height: 24px; /* override ionic default to have a more dense view */
}