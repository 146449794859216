.statsCard {
	border-radius: 0;
	box-shadow: none;
	background-color: #EAF0ED;
	padding: 16px;
	margin: 0;
	margin-bottom: 32px;
}

.statsBlock {
    display: block;
    text-align: center;
    overflow: hidden;
}

.statsChartContainer {
    composes: statsBlock;
    display: block;
    height: 220px;
    position: relative;
    overflow: hidden;
}

.statsChartContainer .title {
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    margin: 0 auto;
    transform: translate(0, -50%);
}

.statsChartContainer .title .icon {
    display: block;
    width: 40px;
    height: 40px;
    margin: 0 auto 8px;
}

.statsChartContainer .title .counter {
    color: var(--ion-color-dark);
    font-size: 25px;
    letter-spacing: 0;
    text-align: center;
    margin: 0;
    margin-bottom: 8px;
}

.statsChartContainer .title .label {
    color: var(--ion-color-dark);
    font-size: 14px;
    letter-spacing: 0;
    text-align: center;
    text-transform: uppercase;
    margin: 0;
}

.statsChartContainer .title .label span {
    display: block;
    color: var(--ion-color-medium-shade);
    text-transform: none;
    margin-top: 2px;
}

/*
 * Chart Animation
 */
.statsDonut {
	transform: rotate(-90deg);
}

.statsDonut .circle_animation {
    stroke: var(--ion-color-gold);
    stroke-linecap: round; 
    stroke-dasharray: 615; /* this value is the pixel circumference of the circle */ 
    stroke-dashoffset: 615;
    animation: donut 1s ease-out forwards;
}

@keyframes donut {
    0% { stroke-dashoffset: 615 };
}