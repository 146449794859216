.pageCover {
    position: relative;
}

.pageTitleWrapper {
    display: block;
    position: absolute;
    bottom: 0;
    background: #313131;
    background: linear-gradient(180deg, rgba(44, 44, 44, 0) 0%, rgba(44,44,44,.5) 30%, rgba(44,44,44,1) 100%);
    width: 100%;
    height: 148px;
}

.pageTitle {
    padding-left: 24px;
    padding-right: 24px;
}

.pageTitle h1 {
    display: block;
    color: #ffffff;
    opacity: .95;
}

.pageTitle .subline {
    font-family: basic-sans, sans-serif;
    font-size: 14px;
    font-weight: 600;
    color: #fff;
    opacity: .85;
}

.pageTitle .subline .iconText {
    display: inline-block;
    margin-right: 8px;
}

.pageTitle .subline .iconText ion-icon {
    float: left;
    margin-top: 1px;
    margin-right: -1px;
}

.tabWrapper {
    padding: 24px;
    padding-top: 0;
}

.tabIngredients ion-item {
    --padding-start: 0;
}

.tabIngredients ion-label {
    white-space: normal !important;
}

.tabIngredients .quantity {
    color: var(--ion-color-dark-tint);
}

.tabIngredients .toolbar {
    display: flex;
    justify-content: space-between;
    background-color: #fff;
    padding-bottom: 8px;
}

.tabIngredients .toolbar .servingsSelector {
    display: flex;
}

.servingsSelector ion-label {
    padding-top: 12px;
    padding-bottom: 12px;
}

.datepickerTitle {
    position: absolute;
    left: 16px;
    top: -4px;
}

.hidden {
    display: none;
}

.fabLabel p {
    font-style: italic;
    margin-left: 24px;
    margin-right: 80px;
}

:global(.ios) .datepickerTitle {
    left: 50%;
    transform: translate(-50%);
}

@media (min-width: 1025px) {
    .fabLabel {
        text-align: center;
        margin-left: auto;
        margin-right: auto;
    }

    .pageCover .coverContainer {
        border-radius: 4px;
        overflow: hidden;
    }

    .pageTitleWrapper {
        position: initial;
        background: none;
        height: auto;
    }

    .pageTitle {
        padding: 0;
    }

    .pageTitle h1 {
        color: var(--ion-color-dark);
    }

    .pageTitle .subline {
        color: var(--ion-color-dark);
    }
}

@media (max-width: 360px) {
    /* on extra small devices, the recipe title block is cut off */
    .pageTitleWrapper {
        height: 180px;
    }
}