.pageHeader:after {
    /* remove shadow */
    display: none;
}

.pageToolbar {
    --color: #fff;
    --background: #235536;
    --border-color: #235536;
    --border-width: 0;
}

.pageToolbar .title {
    --color: #fff;
}

.pageToolbar ion-icon {
    color: #fff;
}

.searchbar {
    display: block;
}

.emptyPlaceholder {
    display: block;
    text-align: center;
    padding-left: 16px;
    padding-right: 16px;
    margin-top: 10%;
}

.footerActions {
    display: block;
    text-align: center;
}

:global(.ios) .searchbar ion-searchbar {
    --background: #fff;
}
