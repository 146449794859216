.container {
    /* the container is needed to fix an issue with the button being hidden behind the tab bar on Safari*/
    position: fixed;
    bottom: 16px;
    width: 100%;
}

.container .sticky {
    position: absolute;
    z-index: 999; 
    bottom: 46px; /* the height of the button */
    left: 50%;
    transform: translate(-50%, 0);
}

:global(.plt-android) .container .sticky {
    bottom: 0px;
}