.inputFloatingIcon, 
.inputFloatingButton {
    font-size: 14px;
    padding: 0;
    position: absolute;
    top: 12px;
    right: 0;
    z-index: 99;
}

.inputFloatingButton:hover {
    cursor: pointer;
} 

.section {
    display: block;
    margin-bottom: 32px;
}

.section .title,
.section .content {
    display: block;
    padding-left: 16px;
    padding-right: 16px;
}

.checkbox .label {
    margin-left: 16px;
}

.section.sectionContact ion-item ion-icon {
    margin-right: 8px;
}