.groceryItem {}

.quantity {
    color: var(--ion-color-dark-tint);
}

.groceryItem .markIcon {
    color: var(--ion-color-light-shade);
    font-size: 18px;
}

.recipesList {
    padding-top: 8px;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.2s ease-out;
}

.recipesList.expanded {
    max-height: initial
}

.groceryItem ion-label {
    white-space: normal !important;
    /*
    overflow: hidden;
    text-overflow: ellipsis;
    */
}

.groceryItem.selected .markIcon {
    color: var(--ion-color-primary);
}

.groceryItem.selected ion-label,
.groceryItem.selected ion-label .quantity {
    color: var(--ion-color-medium-shade);
    text-decoration: line-through;
}  