@import url(https://use.typekit.net/dzw0dtc.css);
@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro&display=swap);
/* Core CSS required for Ionic components to work properly */

/* Basic CSS for apps built with Ionic */

/* Optional CSS utils that can be commented out */

/*
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
*/

.PageCheckoutSuccess_wrapper__10OoM {
    display: block;
    text-align: center;
    padding-top: 56px;
}

.PageCheckoutSuccess_message__1QyY9 {
    display: block;
    margin-top: 24px;
    margin-bottom: 40px;
}
.PageSignin_contentWrapper__1MMup {
    --padding-start: 24px;
    --padding-end: 24px;
}

.PageSignin_contentWrapper__1MMup header {
    text-align: center;
    padding-top: 32px;
    padding-bottom: 16px;
}

.PageSignin_logo__S5rrY {
    display: block;
    background-color: #f8f8f8;
    border-radius: 4px;
    width: 72px;
    height: 72px;
    padding: 16px;
    margin: 0 auto;
    margin-bottom: 32px;
    box-shadow: -1px 1px 1px rgba(165,176,202,0.2), 
                -2px 2px 2px rgba(165,176,202,0.2);
}

.PageSignin_logo__S5rrY span {
    display: block;
    background-image: url(/static/media/app-icon-logo.012edd26.svg);
    background-position: center center;
    background-size: contain;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
}

.PageSignin_headerCta__CzjYy {
    text-decoration: underline;
}

.PageSignin_formInputGroup__259kZ, .PageSignin_formGroup__2Lhau {
    display: block;
    margin-bottom: 16px;
}

.PageSignin_formInputGroup__259kZ {
    --background: #f8f8f8;
    --border-radius: 4px;
}

.PageSignin_formGroup__2Lhau.PageSignin_checkBox__33lL5 {
    display: flex;
}

.PageSignin_formActions__273ZF {
    display: block;
    margin-top: 40px;
}

.PageSignin_spinnerContainer__1K2MR {
    display: block;
    text-align: center;
}

.PageSignin_terms__yn-Ls .PageSignin_label__2F0oT {
    color: var(--ion-color-dark-tint);
    font-size: 14px;
    white-space: normal;
}

.PageSignin_terms__yn-Ls .PageSignin_label__2F0oT.PageSignin_checkbox__2Up3S {
    margin-left: 16px;
}

.PageSignin_terms__yn-Ls .PageSignin_label__2F0oT.PageSignin_center__3wlUb {
    text-align: center;
}

.PageSignin_warningToast__2xc7P {
    --start: 24px;
    --end: 24px;
}

.PageSignin_resetLink__2Cq-5 {
    font-size: 14px;
}

/* Desktop */
.plt-desktop .PageSignin_contentWrapper__1MMup {
    --background: #fff;
}
.MembershipBanner_banner__3_G_1 {
    display: block;
    /*color: #fff;*/
    color: var(--ion-color-dark-tint);
    text-align: center;
    background: #F6D2B6;
    background: linear-gradient(#ffffff, #ffeddf);
    border: 1px solid #FF7F3C;
    border-radius: 4px;
    padding: 16px;
    box-shadow: 0 1px 2px rgba(165,176,202,0.07), 
                0 2px 4px rgba(165,176,202,0.07), 
                0 4px 8px rgba(165,176,202,0.07), 
                0 8px 16px rgba(165,176,202,0.07), 
                0 16px 32px rgba(165,176,202,0.07);
}

.MembershipBanner_banner__3_G_1.MembershipBanner_margin__3NoVA {
    margin: 16px;
}

.MembershipBanner_banner__3_G_1 .MembershipBanner_subheader__2zgOm {
    color: #BA3815;
    text-transform: uppercase;
    font-size: 14px;
    letter-spacing: 0.6px;
    /*opacity: .6;*/
}

.MembershipBanner_banner__3_G_1 p {
    /*color: #fff;*/
    color: var(--ion-color-dark-tint);
    font-size: 18px;
    line-height: 32px;
}

.MembershipBanner_banner__3_G_1 .MembershipBanner_highlight__2OOux {
    color: var(--ion-color-dark-tint);
    font-weight: bold;
    /*background-color: #BA3815;*/
}

.MembershipBanner_banner__3_G_1 button {
    display: block;
    outline: 0;
    /*color: var(--ion-color-dark-tint);*/
    color: #fff;
    font-size: 18px;
    text-transform: uppercase;
    text-decoration: none;
    background-color: #BA3815;
    width: 100%;
    padding: 16px;
}

.MembershipBanner_banner__3_G_1 button:focus {
    background-color: var(--ion-color-warning-shade);
}
.PageHome_widgetsList__2gveq {
    padding-bottom: 32px;
}

.PageHome_widget__3QNeA {}

.PageHome_widgetSubtitle__1Tiyd {
    font-size: 12px;
    color: var(--ion-color-medium-shade);
    text-transform: uppercase;
    font-weight: 600;
    letter-spacing: .07rem;
}

.PageHome_widgetMeal__2zzLd .PageHome_button__33vpz,
.PageHome_widgetGrocery__2K77r .PageHome_button__33vpz {
    text-transform: uppercase;
    position: relative;
}

.PageHome_widgetMeal__2zzLd .PageHome_button__33vpz .PageHome_icon__2SZyM,
.PageHome_widgetGrocery__2K77r .PageHome_button__33vpz .PageHome_icon__2SZyM {
    position: absolute;
    top: 2px;
    margin-left: 4px;
}

.PageHome_widgetMeal__2zzLd, .PageHome_widgetGrocery__2K77r {
    border-radius: 0;
    box-shadow: 0 1px 2px rgba(165,176,202,0.07), 
                0 2px 4px rgba(165,176,202,0.07), 
                0 4px 8px rgba(165,176,202,0.07), 
                0 8px 16px rgba(165,176,202,0.07), 
                0 16px 32px rgba(165,176,202,0.07);
}

.PageHome_widgetMeal__2zzLd {
    border-radius: 0;
}

.PageHome_widgetMeal__2zzLd .PageHome_coverImg__3VDdM {
    display: block;
    background-size: cover;
    background-repeat: no-repeat;
    height: 300px;
}

.PageHome_widgetMeal__2zzLd .PageHome_fab__1W0Zl {
    bottom: 88px;
    right: 16px;
}

.PageHome_widgetMeal__2zzLd ion-card-title {
    margin-bottom: 8px;
}

.PageHome_widgetMeal__2zzLd ion-card-title a {
    color: #313131;
}

/* Desktop devices */
.plt-desktop .PageHome_widgetMeal__2zzLd .PageHome_coverImg__3VDdM {
    height: 440px;
}
.CaloriesDonut_statsCard__1HTph {
	border-radius: 0;
	box-shadow: none;
	background-color: #EAF0ED;
	padding: 16px;
	margin: 0;
	margin-bottom: 32px;
}

.CaloriesDonut_statsBlock__3orsF {
    display: block;
    text-align: center;
    overflow: hidden;
}

.CaloriesDonut_statsChartContainer__2yh9u {
    display: block;
    height: 220px;
    position: relative;
    overflow: hidden;
}

.CaloriesDonut_statsChartContainer__2yh9u .CaloriesDonut_title__Yu2bo {
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    margin: 0 auto;
    transform: translate(0, -50%);
}

.CaloriesDonut_statsChartContainer__2yh9u .CaloriesDonut_title__Yu2bo .CaloriesDonut_icon__1SH1_ {
    display: block;
    width: 40px;
    height: 40px;
    margin: 0 auto 8px;
}

.CaloriesDonut_statsChartContainer__2yh9u .CaloriesDonut_title__Yu2bo .CaloriesDonut_counter__yKMu6 {
    color: var(--ion-color-dark);
    font-size: 25px;
    letter-spacing: 0;
    text-align: center;
    margin: 0;
    margin-bottom: 8px;
}

.CaloriesDonut_statsChartContainer__2yh9u .CaloriesDonut_title__Yu2bo .CaloriesDonut_label__Hx3Zr {
    color: var(--ion-color-dark);
    font-size: 14px;
    letter-spacing: 0;
    text-align: center;
    text-transform: uppercase;
    margin: 0;
}

.CaloriesDonut_statsChartContainer__2yh9u .CaloriesDonut_title__Yu2bo .CaloriesDonut_label__Hx3Zr span {
    display: block;
    color: var(--ion-color-medium-shade);
    text-transform: none;
    margin-top: 2px;
}

/*
 * Chart Animation
 */
.CaloriesDonut_statsDonut__38G1o {
	transform: rotate(-90deg);
}

.CaloriesDonut_statsDonut__38G1o .CaloriesDonut_circle_animation__3ulu8 {
    stroke: var(--ion-color-gold);
    stroke-linecap: round; 
    stroke-dasharray: 615; /* this value is the pixel circumference of the circle */ 
    stroke-dashoffset: 615;
    animation: CaloriesDonut_donut__2eZLq 1s ease-out forwards;
}

@keyframes CaloriesDonut_donut__2eZLq {
    0% { stroke-dashoffset: 615 };
}
.WidgetCalories_widgetCalories__11HU7 {
    padding-top: 32px;
    padding-bottom: 32px;
    padding-top: 24px;
    padding-bottom: 8px;
}

.WidgetCalories_widgetCalories__11HU7 .WidgetCalories_actionsBar__2ZGdI {
    display: block;
    text-align: center;
    border-bottom: 1px solid var(--ion-color-light);
    padding-top: 16px;
    padding-bottom: 16px;
}

.WidgetCalories_widgetCalories__11HU7 .WidgetCalories_actionsBar__2ZGdI ion-button {
    --padding-start: 40px;
    --padding-end: 40px;
}
.GroceryItem_groceryItem__1_Nd2 {}

.GroceryItem_quantity__L14Tf {
    color: var(--ion-color-dark-tint);
}

.GroceryItem_groceryItem__1_Nd2 .GroceryItem_markIcon__1bET2 {
    color: var(--ion-color-light-shade);
    font-size: 18px;
}

.GroceryItem_recipesList__xidkf {
    padding-top: 8px;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.2s ease-out;
}

.GroceryItem_recipesList__xidkf.GroceryItem_expanded__3Z-wt {
    max-height: none;
    max-height: initial
}

.GroceryItem_groceryItem__1_Nd2 ion-label {
    white-space: normal !important;
    /*
    overflow: hidden;
    text-overflow: ellipsis;
    */
}

.GroceryItem_groceryItem__1_Nd2.GroceryItem_selected__1a0Om .GroceryItem_markIcon__1bET2 {
    color: var(--ion-color-primary);
}

.GroceryItem_groceryItem__1_Nd2.GroceryItem_selected__1a0Om ion-label,
.GroceryItem_groceryItem__1_Nd2.GroceryItem_selected__1a0Om ion-label .GroceryItem_quantity__L14Tf {
    color: var(--ion-color-medium-shade);
    text-decoration: line-through;
}  
.PageEmptyPlaceholder_container__3thc3 {
    display: block;
    text-align: center;
    height: 100vh;
}

.PageEmptyPlaceholder_verticalAlign__2gUGy {
    display: block;
    position: absolute;
    top: calc(50% - 148px);
    width: 100%;
    height: 160px;
    padding: 24px;
    opacity: .6;
}

.PageEmptyPlaceholder_icon__vcRCC {
    font-size: 24px;
}
.PageGrocery_groceryToolbar__UPxCD {
    display: flex;
    justify-content: space-between;
    background-color: #fff;
    padding-left: 20px;
    padding-right: 8px;
    padding-bottom: 8px;
}

.PageGrocery_groceryToolbar__UPxCD .PageGrocery_servingsSelector__2t7aM {
    display: flex;
}

.PageGrocery_groceryToolbar__UPxCD .PageGrocery_servingsSelector__2t7aM ion-label {
    padding-top: 12px;
    padding-bottom: 12px;
}

.PageGrocery_listHeader__2NmAb {
    min-height: 24px; /* override ionic default to have a more dense view */
}
.PageOnboarding_onboarding__zvRRr ion-content {
    max-width: 740px;
    left: 50%;
    transform: translate(-50%, 0);
}

ion-slides {
    height: 100%;
}

ion-slide {
    padding: 0;
}

.PageOnboarding_body__jeYl6 {
    width: 100%;
    height: 100vh;
}

.PageOnboarding_cover__8ROeQ {
    background-image: url(//eatvegly-lon.s3.eu-west-2.amazonaws.com/app-assets/onboarding-bg.svg);
    background-position: center bottom;
    background-repeat: no-repeat;
    display: block;
    height: 50%;
    margin: 0 auto;
    overflow: hidden;
}

.PageOnboarding_cover__8ROeQ img {
    display: block;
    width: auto;
    height: 100%;
    margin: 0 auto;
}

.PageOnboarding_content__1t4Ml {
    display: block;
    padding-top: 24px;
    padding-bottom: 16px;
}

.PageOnboarding_slide__DBZOS p {
    display: block;
    margin-left: 16px;
    margin-right: 16px;
}

.PageOnboarding_footerActions__ptJUD, .PageOnboarding_headerActions__1otag {
    display: block;
    position: absolute;
    z-index: 999;
    bottom: 0;
    width: 100%;
}

.PageOnboarding_headerActions__1otag {
    top: 0;
}

.PageOnboarding_headerActions__1otag span {
    display: flex;
    justify-content: flex-end;
}

.PageOnboarding_footerActions__ptJUD span {
    display: flex;
    justify-content: space-between;
}
.RecipeItem_card__1jN31 {
    display: flex;
    flex-direction: row;
    margin-left: 16px;
    margin-right: 16px;
    margin-bottom: 8px;
    background-color: #fff;
    box-shadow: 0 1px 2px rgba(165,176,202,0.07), 
                0 2px 4px rgba(165,176,202,0.07), 
                0 4px 8px rgba(165,176,202,0.07), 
                0 8px 16px rgba(165,176,202,0.07), 
                0 16px 32px rgba(165,176,202,0.07);
}

.RecipeItem_card__1jN31 .RecipeItem_thumb__1VFvR {
    flex: 0 0 80px;
    width: 80px;
    height: 80px;
    margin: 8px;
    margin-right: 16px;
    position: relative;
}

.RecipeItem_card__1jN31 .RecipeItem_lockOverlay__2hE6b {
    position: absolute;
    width: 100%;
    height: 100%;
}

.RecipeItem_card__1jN31 .RecipeItem_lockOverlay__2hE6b ion-icon {
    color: var(--ion-color-primary-contrast);
    margin: 0;
    margin-left: 40%;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
}

.RecipeItem_card__1jN31 .RecipeItem_tagBanner__27YOS {
    color: var(--ion-color-light);
    font-size: 14px;
    /*background-color: rgba(201, 188, 162, 0.6);*/
    background-color: var(--ion-color-primary);
    padding: 4px;
    position: absolute;
    width: 24px;
    height: 24px;
    bottom: 0;
    right: 0;
    opacity: .8;
}

.RecipeItem_card__1jN31 .RecipeItem_tagBanner__27YOS ion-icon {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
}

.RecipeItem_iconText__Ec1yp {
    display: inline-block;
    margin-right: 4px;
}

.RecipeItem_iconText__Ec1yp ion-icon {
    float: left;
    margin-top: 1px;
    margin-right: -1px;
}

.RecipeItem_veganBadge__1Yd8m {
    color: #4a9009;
}

.RecipeItem_vyIonLabel__19AAi {
    overflow: hidden;
    width: 100%;
    margin: 8px;
    margin-left: 0;
}

.RecipeItem_vyIonLabel__19AAi .RecipeItem_labelInfo__2fUfB {
    font-size: 12px;
    color: var(--ion-color-medium-shade);
    text-transform: uppercase;
    font-weight: 600;
    letter-spacing: .07rem;
}

.RecipeItem_vyIonLabel__19AAi .RecipeItem_title__2wXTR {
    font-size: 16px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.RecipeItem_vyIonLabel__19AAi .RecipeItem_footer__1XLX5 {
    display: flex;
    align-content: center;
    justify-content: space-between;
    margin-top: 4px;
    margin-left: -12px;
}

.RecipeItem_vyIonLabel__19AAi .RecipeItem_footer__1XLX5 ion-button {
    font-size: 12px;
    font-weight: 600;
}

.RecipeItem_vyIonLabel__19AAi .RecipeItem_footer__1XLX5 .RecipeItem_labelInfo__2fUfB {
    color: var(--ion-color-gold);
    font-size: 10px;
    padding-top: 11px;
    padding-bottom: 11px;
}

.RecipeItem_labelInfo__2fUfB.RecipeItem_low__38hLF {
    color: #2ABC69 !important;
}

.RecipeItem_labelInfo__2fUfB.RecipeItem_medium__1wYhb {
    color: #159CDE !important;
}

.RecipeItem_labelInfo__2fUfB.RecipeItem_high__1pfi5 {
    color: #0376B8 !important;
}

.RecipeItem_subhead__21yuy {
    display: flex;
    flex-grow: 1;
    justify-content: space-between;
    font-family: basic-sans, sans-serif;
    font-size: 12px;
    font-weight: 600;
    color: var(--ion-color-medium-shade);
    text-transform: uppercase;
}

.RecipeItem_subhead__21yuy div.RecipeItem_labelInfo__2fUfB > .RecipeItem_iconText__Ec1yp:last-child {
    margin-right: 0;
}
.StatsPanel_headSection__1pfHH {
    border-bottom: 1px solid var(--ion-color-light);
    padding-top: 8px;
    padding-bottom: 8px;
}

.StatsPanel_subheader__1Es1h {
    text-align: center;
}

.StatsPanel_subheader__1Es1h p {
    line-height: 8px;
}

.StatsPanel_disclaimer__1Vdd3 p {
    display: block;
    text-align: center;
    padding-left: 16px;
    padding-right: 16px;
}

.StatsPanel_contentSection__16Hgq {
    padding: 16px;
}

.StatsPanel_contentSection__16Hgq ul {
    padding: 0;
    margin: 0;
}

.StatsPanel_contentSection__16Hgq li {
    list-style: none;
    padding: 0;
    margin: 0;
}

li.StatsPanel_nutritionItem__2pWYZ {
    margin-bottom: 16px;
}

.StatsPanel_nutritionItem__2pWYZ > span {
    display: flex;
    justify-content: space-between;
}

.StatsPanel_progressBar__2OCfX {
    display: block;
    width: 100%;
	overflow: hidden;
}

.StatsPanel_progressBar__2OCfX span {
    display: block;
}

.StatsPanel_progressBar__2OCfX .StatsPanel_bar__3oG1v {
    /*background: var(--ion-color-light-tint);*/
    background: #EAF0ED;
}

.StatsPanel_progressBar__2OCfX .StatsPanel_progress__v59R0 {
    animation: StatsPanel_loader__1WrGU 1s ease-out forwards;
    background: var(--ion-color-primary);
    color: #fff;
    padding: 1px; /* bar size */
    width: 0;
}

@keyframes StatsPanel_loader__1WrGU {
    0% { width: 0 };
}
.PlanDetail_dayplanList__2IlLD {
    background-color: transparent;
}

.PlanDetail_dayplanHeader__2anbN {
    color: var(--ion-color-dark-tint);
}

.PlanDetail_calBtn__1LdSp {
    margin-right: 16px;
}

.PlanDetail_calBtn__1LdSp ion-icon {
    float: left;
    margin-top: 2px;
    margin-right: 4px;
}

.PlanDetail_actionsWrapper__2U_CW {
    display: block;
    padding-top: 16px;
    padding-left: 16px;
    padding-right: 16px;
}

.PlanDetail_addMealBtn__1XFSa {
    display: block;
    color: var(--ion-color-secondary-shade);
    font-size: 14px;
    background-color: #fff;
    border: 1px dashed var(--ion-color-secondary);
    padding: 16px;
    width: 100%;
}

.PlanDetail_emptyDayPlacelholder__2tqMY {
    display: block;
    text-align: center;
}

.PlanDetail_emptyDayPlacelholder__2tqMY span {
    color: var(--ion-color-medium-shade);
}
.FloatingButtonText_container__f3PRY {
    /* the container is needed to fix an issue with the button being hidden behind the tab bar on Safari*/
    position: fixed;
    bottom: 16px;
    width: 100%;
}

.FloatingButtonText_container__f3PRY .FloatingButtonText_sticky__1YPd3 {
    position: absolute;
    z-index: 999; 
    bottom: 46px; /* the height of the button */
    left: 50%;
    transform: translate(-50%, 0);
}

.plt-android .FloatingButtonText_container__f3PRY .FloatingButtonText_sticky__1YPd3 {
    bottom: 0px;
}
.SkeletonPlanItem_card__LMbmD {
    border-radius: 0;
    box-shadow: 0 1px 2px rgba(165,176,202,0.07), 
                0 2px 4px rgba(165,176,202,0.07), 
                0 4px 8px rgba(165,176,202,0.07), 
                0 8px 16px rgba(165,176,202,0.07), 
                0 16px 32px rgba(165,176,202,0.07);
    padding-top: 16px;
    padding-bottom: 16px;
}
.PlanItem_card__hcDj_ {
    border-radius: 0;
    box-shadow: 0 1px 2px rgba(165,176,202,0.07), 
                0 2px 4px rgba(165,176,202,0.07), 
                0 4px 8px rgba(165,176,202,0.07), 
                0 8px 16px rgba(165,176,202,0.07), 
                0 16px 32px rgba(165,176,202,0.07);
}

.PlanItem_card__hcDj_ .PlanItem_content__Meqik {
    overflow: hidden;
}

.PlanItem_card__hcDj_ .PlanItem_title__2lPRS {
    display: flex;
}

.PlanItem_date__1jtuE {
    display: block;
    float: left;
}

.PlanItem_description__41B2F,
.PlanItem_actions__3fI7H {
    display: block;
    width: 100%;
    overflow: hidden;
}

.PlanItem_newChip__QqrmP {
    font-size: 12px;
    font-weight: bold;
    text-transform: uppercase;
    height: 20px;
    margin: 2px;
}

.PlanItem_button__1Wr_h {
    font-size: 14px;
    --background-activated: transparent;
    --background-hover: transparent;
    --padding-start: 0;
    --padding-top: 0;
    --padding-bottom: 0;
}

.PlanItem_button__1Wr_h .PlanItem_icon__s1GH3 {
    font-size: 13px;
    margin-left: 4px;
}

.PagePlans_segmentStore__1tJaR {}

.PagePlans_previewContent__1ceE_ {
    --padding-bottom: 56px;
}

.PagePlans_cardChip__3SnP0 {
    border: 0;
    margin-top: -6px;
}

.PagePlans_pageDesc__3FfjG {
    padding-left: 24px;
    padding-right: 24px;
}
.PagePreviewPlan_hiddenAction__2cs7E {
    display: none !important;
}
.NutritionSheet_headSection__slA5U {
    border-bottom: 1px solid var(--ion-color-light);
    padding-top: 8px;
    padding-bottom: 8px;
}

.NutritionSheet_subheader__fPyNV {
    text-align: center;
}

.NutritionSheet_subheader__fPyNV p {
    line-height: 8px;
}

.NutritionSheet_disclaimer__3ZrbB p {
    display: block;
    text-align: center;
    padding-left: 16px;
    padding-right: 16px;
}

.NutritionSheet_contentSection__34rSn {
    padding: 16px;
}

.NutritionSheet_contentSection__34rSn ul {
    padding: 0;
    margin: 0;
}

.NutritionSheet_contentSection__34rSn li {
    list-style: none;
    padding: 0;
    margin: 0;
}

li.NutritionSheet_nutritionItem__-ohgZ {
    margin-bottom: 16px;
}

.NutritionSheet_nutritionItem__-ohgZ > span {
    display: flex;
    justify-content: space-between;
}

.NutritionSheet_progressBar__o3ynN {
    display: block;
    width: 100%;
	overflow: hidden;
}

.NutritionSheet_progressBar__o3ynN span {
    display: block;
}

.NutritionSheet_progressBar__o3ynN .NutritionSheet_bar__3dKX2 {
    /*background: var(--ion-color-light-tint);*/
    background: #EAF0ED;
}

.NutritionSheet_progressBar__o3ynN .NutritionSheet_progress__3Zx3- {
    animation: NutritionSheet_loader__1ukhi 1s ease-out forwards;
    background: var(--ion-color-primary);
    color: #fff;
    padding: 1px; /* bar size */
    width: 0;
}

@keyframes NutritionSheet_loader__1ukhi {
    0% { width: 0 };
}
.PageRecipeDetail_pageCover__1BmR1 {
    position: relative;
}

.PageRecipeDetail_pageTitleWrapper__1C7_K {
    display: block;
    position: absolute;
    bottom: 0;
    background: #313131;
    background: linear-gradient(180deg, rgba(44, 44, 44, 0) 0%, rgba(44,44,44,.5) 30%, rgba(44,44,44,1) 100%);
    width: 100%;
    height: 148px;
}

.PageRecipeDetail_pageTitle__2NmZS {
    padding-left: 24px;
    padding-right: 24px;
}

.PageRecipeDetail_pageTitle__2NmZS h1 {
    display: block;
    color: #ffffff;
    opacity: .95;
}

.PageRecipeDetail_pageTitle__2NmZS .PageRecipeDetail_subline__30TSA {
    font-family: basic-sans, sans-serif;
    font-size: 14px;
    font-weight: 600;
    color: #fff;
    opacity: .85;
}

.PageRecipeDetail_pageTitle__2NmZS .PageRecipeDetail_subline__30TSA .PageRecipeDetail_iconText__23z_x {
    display: inline-block;
    margin-right: 8px;
}

.PageRecipeDetail_pageTitle__2NmZS .PageRecipeDetail_subline__30TSA .PageRecipeDetail_iconText__23z_x ion-icon {
    float: left;
    margin-top: 1px;
    margin-right: -1px;
}

.PageRecipeDetail_tabWrapper__34T_I {
    padding: 24px;
    padding-top: 0;
}

.PageRecipeDetail_tabIngredients__3Gaps ion-item {
    --padding-start: 0;
}

.PageRecipeDetail_tabIngredients__3Gaps ion-label {
    white-space: normal !important;
}

.PageRecipeDetail_tabIngredients__3Gaps .PageRecipeDetail_quantity__3fyvp {
    color: var(--ion-color-dark-tint);
}

.PageRecipeDetail_tabIngredients__3Gaps .PageRecipeDetail_toolbar__B88h- {
    display: flex;
    justify-content: space-between;
    background-color: #fff;
    padding-bottom: 8px;
}

.PageRecipeDetail_tabIngredients__3Gaps .PageRecipeDetail_toolbar__B88h- .PageRecipeDetail_servingsSelector__5VgPN {
    display: flex;
}

.PageRecipeDetail_servingsSelector__5VgPN ion-label {
    padding-top: 12px;
    padding-bottom: 12px;
}

.PageRecipeDetail_datepickerTitle__2Ck_m {
    position: absolute;
    left: 16px;
    top: -4px;
}

.PageRecipeDetail_hidden__1pVOD {
    display: none;
}

.PageRecipeDetail_fabLabel__vQlUv p {
    font-style: italic;
    margin-left: 24px;
    margin-right: 80px;
}

.ios .PageRecipeDetail_datepickerTitle__2Ck_m {
    left: 50%;
    transform: translate(-50%);
}

@media (min-width: 1025px) {
    .PageRecipeDetail_fabLabel__vQlUv {
        text-align: center;
        margin-left: auto;
        margin-right: auto;
    }

    .PageRecipeDetail_pageCover__1BmR1 .PageRecipeDetail_coverContainer__j6hVU {
        border-radius: 4px;
        overflow: hidden;
    }

    .PageRecipeDetail_pageTitleWrapper__1C7_K {
        position: static;
        position: initial;
        background: none;
        height: auto;
    }

    .PageRecipeDetail_pageTitle__2NmZS {
        padding: 0;
    }

    .PageRecipeDetail_pageTitle__2NmZS h1 {
        color: var(--ion-color-dark);
    }

    .PageRecipeDetail_pageTitle__2NmZS .PageRecipeDetail_subline__30TSA {
        color: var(--ion-color-dark);
    }
}

@media (max-width: 360px) {
    /* on extra small devices, the recipe title block is cut off */
    .PageRecipeDetail_pageTitleWrapper__1C7_K {
        height: 180px;
    }
}
.PageAccount_inputFloatingIcon__rLG9K, 
.PageAccount_inputFloatingButton__3GxsE {
    font-size: 14px;
    padding: 0;
    position: absolute;
    top: 12px;
    right: 0;
    z-index: 99;
}

.PageAccount_inputFloatingButton__3GxsE:hover {
    cursor: pointer;
} 

.PageAccount_section__38jlq {
    display: block;
    margin-bottom: 32px;
}

.PageAccount_section__38jlq .PageAccount_title__2Nb0A,
.PageAccount_section__38jlq .PageAccount_content__36QfZ {
    display: block;
    padding-left: 16px;
    padding-right: 16px;
}

.PageAccount_checkbox__QIBoy .PageAccount_label__3MNZd {
    margin-left: 16px;
}

.PageAccount_section__38jlq.PageAccount_sectionContact__TrC9P ion-item ion-icon {
    margin-right: 8px;
}
.FeedbackWidget_widget__2vTOU {

}

.FeedbackWidget_widget__2vTOU ion-content {
    --padding-top: 24px;
    --padding-start: 24px;
    --padding-end: 24px;
    --padding-bottom: 24px;
}

.FeedbackWidget_widget__2vTOU ion-content ion-toolbar {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    --padding-start: 24px;
    --padding-end: 24px;
    --padding-bottom: 16px;
}

.FeedbackWidget_widget__2vTOU ion-textarea {
    --background: #f8f8f8;
    border-radius: 4px;
    border-bottom: 2px solid var(--ion-color-light-shade);
    padding: 8px 16px;
}

.FeedbackWidget_widget__2vTOU ion-textarea.FeedbackWidget_activeTextbox__1kHSx {
    border-color: var(--ion-color-primary);
}

.FeedbackWidget_widget__2vTOU form {
    display: block;
}
.PageEditPassword_stickyAction__iaFfD {
    display: block;
    position: absolute;
    background-color: #fff;
    padding: 16px;
    bottom: 0;
    width: 100%;
    box-shadow: 
        0 -1px 2px rgba(165,176,202,0.07), 
        0 -2px 4px rgba(165,176,202,0.07), 
        0 -4px 8px rgba(165,176,202,0.07), 
        0 -8px 16px rgba(165,176,202,0.07), 
        0 -16px 32px rgba(165,176,202,0.07);
}
.PageReset_contentWrapper__1EBrX {
    --padding-start: 24px;
    --padding-end: 24px;
}

.PageReset_contentWrapper__1EBrX header {
    text-align: center;
    padding-top: 16px;
    padding-bottom: 16px;
}

.PageReset_headerCta__2AI9A {
    text-decoration: underline;
}

.PageReset_formInputGroup__n5U_u, .PageReset_formGroup__LEkh4 {
    display: block;
    margin-bottom: 16px;
}

.PageReset_formInputGroup__n5U_u {
    --background: #f8f8f8;
    --border-radius: 4px;
}

.PageReset_formGroup__LEkh4.PageReset_checkBox__2pe0g {
    display: flex;
}

.PageReset_formActions__pIt4u {
    display: block;
    margin-top: 40px;
}

.PageReset_spinnerContainer__2GfYU {
    display: block;
    text-align: center;
}

.PageReset_toast__11jiE {
    --start: 24px;
    --end: 24px;
}

.PageReset_formLink__16UfL {
    display: block;
    font-size: 14px;
    margin-top: 32px;
}

/* Desktop */
.plt-desktop .PageReset_contentWrapper__1EBrX {
    --background: #fff;
}
.PageSubs_contentWrapper__3D6Cm {
    --padding-start: 16px;
    --padding-end: 16px;
    --padding-bottom: 140px; /* allow scrolling gap for sticky checkout */
}

.PageSubs_contentWrapper__3D6Cm footer {
    display: block;
    text-align: center;
    margin-top: 8px;
}

.PageSubs_contentWrapper__3D6Cm footer .PageSubs_disclaimer__2bkjy {
    display: block;
    margin-bottom: 8px;
}

.PageSubs_benefits__qSp8u ion-item {
    --padding-top: 8px;
    --padding-bottom: 8px;
}

.PageSubs_benefits__qSp8u ion-icon,
.PageSubs_otherBenefits__3D0ia ion-icon {
    color: var(--ion-color-primary);
    margin-right: 8px;
}

.PageSubs_benefits__qSp8u ion-label,
.PageSubs_otherBenefits__3D0ia ion-label {
    font-size: 14px;
    white-space: normal !important;
}

.PageSubs_otherBenefits__3D0ia {
    margin-top: 24px;
}

.PageSubs_otherBenefits__3D0ia h6 {
    color: var(--ion-color-dark-tint);
}

.PageSubs_passSelector__2-x17 h6 {
    margin-bottom: 16px;
}

.PageSubs_productSelection__2gXSR {
    display: block;
    background: #fff;
    background: linear-gradient(#fff, var(--ion-color-light));
    border: 1px solid var(--ion-color-medium-tint);
    border-radius: 4px;
    width: 100%;
    padding: 16px;
    margin-bottom: 8px;
}

.PageSubs_productSelection__2gXSR p {
    display: block;
    color: var(--ion-color-dark-tint);
    font-size: 18px;
    margin: 0;
    margin-bottom: 8px;
}

.PageSubs_productSelection__2gXSR p.PageSubs_subline__1i-sh {
    font-size: 14px;
    opacity: .8;
    margin-bottom: 0;
}

.PageSubs_productSelection__2gXSR ion-icon {
    color: var(--ion-color-primary);
    font-size: 24px;
    margin-bottom: 16px;
}

.PageSubs_productSelection__2gXSR.PageSubs_selected__cjfS4 {
    border: 2px solid var(--ion-color-primary);
    box-shadow: 0 1px 2px rgba(165,176,202,0.07), 
                0 2px 4px rgba(165,176,202,0.07), 
                0 4px 8px rgba(165,176,202,0.07), 
                0 8px 16px rgba(165,176,202,0.07), 
                0 16px 32px rgba(165,176,202,0.07);
}

.PageSubs_stickyCheckout__BwuVX {
    display: block;
    position: absolute;
    z-index: 999;
    background-color: #fff;
    padding: 16px;
    bottom: 0;
    width: 100%;
    box-shadow: 
        0 -1px 2px rgba(165,176,202,0.07), 
        0 -2px 4px rgba(165,176,202,0.07), 
        0 -4px 8px rgba(165,176,202,0.07), 
        0 -8px 16px rgba(165,176,202,0.07), 
        0 -16px 32px rgba(165,176,202,0.07);
}

.PageSubs_strikeText__1fqM5 {
    text-decoration: line-through;
    color: #BA3815;
}

.PageSubs_saleBanner__OiD1R {
    padding-left: 22px;
    margin-bottom: 8px;
}

.PageSubs_sale-banner__1EhHh ion-item {
    --background: transparent;
}

.PageSubs_saleBanner__OiD1R ion-icon,
.PageSubs_saleBanner__OiD1R ion-label {
    color: /*var(--ion-color-warning);*/ #BA3815;
}

.PageSubs_saleBanner__OiD1R ion-icon {
    margin-right: 8px;
}

.PageSubs_saleBanner__OiD1R ion-label {
    white-space: normal !important;
}

@media (min-width: 1025px) {
    .PageSubs_stickyCheckout__BwuVX {
        background-color: var(--app-desktop-background);
    }
}
.BackdropContent_backdropContent___7jyd {
    --background: #235536;
}

.BackdropContent_backdropBackLayer__2mJT9 {
}

.BackdropContent_backdropFrontLayer__28UZ8 {
    display: block;
    background-color: #fff;
    border-top-left-radius: 24px;
    border-top-right-radius: 24px;
    height: 100%;
    padding-bottom: 24px;
    overflow-y: hidden;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 999;
    transition: top .3s ease-in-out;
}

.BackdropContent_backdropFrontLayer__28UZ8 header {
    display: block;
    padding: 16px;
    padding-bottom: 8px;
}

.BackdropContent_backdropFrontLayer__28UZ8 .BackdropContent_title__3dVb_ {
    font-size: 16px;
    color: #235536;
    letter-spacing: 0.2px;
    line-height: 28px;
    margin: 0;
}

.BackdropContent_backdropFrontLayer__28UZ8 .BackdropContent_description__5lYzK {
    font-size: 12px;
    letter-spacing: 0.1px;
    line-height: 20px;
    margin: 0;
}

.BackdropContent_backdropFrontLayer__28UZ8 .BackdropContent_content__1SQ0D {
    display: block;
    overflow-y: scroll;
    height: 100%;
    padding-bottom: 56px;
}
.PageRecipeSearch_pageHeader__1FHco:after {
    /* remove shadow */
    display: none;
}

.PageRecipeSearch_pageToolbar__3Q8It {
    --color: #fff;
    --background: #235536;
    --border-color: #235536;
    --border-width: 0;
}

.PageRecipeSearch_pageToolbar__3Q8It .PageRecipeSearch_title__k1kVW {
    --color: #fff;
}

.PageRecipeSearch_pageToolbar__3Q8It ion-icon {
    color: #fff;
}

.PageRecipeSearch_searchbar__az5Wm {
    display: block;
}

.PageRecipeSearch_emptyPlaceholder__1gVKf {
    display: block;
    text-align: center;
    padding-left: 16px;
    padding-right: 16px;
    margin-top: 10%;
}

.PageRecipeSearch_footerActions__vUYub {
    display: block;
    text-align: center;
}

.ios .PageRecipeSearch_searchbar__az5Wm ion-searchbar {
    --background: #fff;
}

:root {
  --ion-font-family: 'Source Sans Pro', Arial, sans-serif;

  /* COLOR SCHEME */
  --ion-color-primary: #235536;
  --ion-color-primary-rgb: 35,85,54;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255,255,255;
  --ion-color-primary-shade: #1f4b30;
  --ion-color-primary-tint: #39664a;

  --ion-color-secondary: #C3B498;
  --ion-color-secondary-rgb: 195,180,152;
  --ion-color-secondary-contrast: #000000;
  --ion-color-secondary-contrast-rgb: 0,0,0;
  --ion-color-secondary-shade: #ac9e86;
  --ion-color-secondary-tint: #c9bca2;

  --ion-color-gold: #cea668;

  --ion-color-tertiary: #589077;
  --ion-color-tertiary-rgb: 88,144,119;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255,255,255;
  --ion-color-tertiary-shade: #4d7f69;
  --ion-color-tertiary-tint: #699b85;

  --ion-color-success: #54A40A;
  --ion-color-success-rgb: 84,164,10;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255,255,255;
  --ion-color-success-shade: #4a9009;
  --ion-color-success-tint: #65ad23;

  --ion-color-warning: #F8C01C;
  --ion-color-warning-rgb: 248,192,28;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0,0,0;
  --ion-color-warning-shade: #daa919;
  --ion-color-warning-tint: #f9c633;

  --ion-color-danger: #D20606;
  --ion-color-danger-rgb: 210,6,6;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255,255,255;
  --ion-color-danger-shade: #b90505;
  --ion-color-danger-tint: #d71f1f;

  --ion-color-dark: #35342D;
  --ion-color-dark-rgb: 53,52,45;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255,255,255;
  --ion-color-dark-shade: #2f2e28;
  --ion-color-dark-tint: #494842;

  --ion-color-medium: #A5B0CA;
  --ion-color-medium-rgb: 165,176,202;
  --ion-color-medium-contrast: #000000;
  --ion-color-medium-contrast-rgb: 0,0,0;
  --ion-color-medium-shade: #919bb2;
  --ion-color-medium-tint: #aeb8cf;

  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244,244,244;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0,0,0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;

  --app-desktop-background: #f8f8f8;
}

/**
 * Typography
 */
body {
  color: var(--ion-color-dark);
  font-family: 'Source Sans Pro', Arial, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 16px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #313131;
  font-family: basic-sans, sans-serif;
  font-weight: 600;
  font-style: normal;
}

h1 {
  line-height: 2.2rem;
}

p, ul, ul li {
  color: var(--ion-color-dark-tint);
  font-family: 'Source Sans Pro', Arial, sans-serif;
  font-weight: 400;
  font-style: normal;
  letter-spacing: 0.3px;
}

.caption {
  color: var(--ion-color-dark-tint);
  font-size: 14px;
  letter-spacing: 0.4px;
}

a {
  text-decoration: none;
}

/** 
 * Ionic customisations
 */
ion-button {
  --box-shadow: none;
  --border-radius: 18px;
  --padding-bottom: 8px;
  --padding-end: 16px;
  --padding-start: 16px;
  --padding-top: 8px;
  font-size: 14px;
}

ion-button.lg {
  --padding-bottom: 16px;
  --padding-top: 16px;
}

ion-header::after {
  background-image: none !important;
  bottom: 0px !important;
  box-shadow: 0 1px 2px rgba(165,176,202,0.07), 
              0 2px 4px rgba(165,176,202,0.07), 
              0 4px 8px rgba(165,176,202,0.07), 
              0 8px 16px rgba(165,176,202,0.07), 
              0 16px 32px rgba(165,176,202,0.07);
}

ion-card {
  margin-left: 16px;
  margin-right: 16px;
}

ion-label {
  letter-spacing: 0.3px;
}

/**
 * Global styles
 */
ion-header.no-shadow::after {
  background-image: none;
}

.subtitle {
  font-size: 12px;
  color: var(--ion-color-medium-shade);
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: .07rem;
}

@media (min-width: 1025px) {
  /*
  .plt-desktop ion-content {
    --background: var(--app-desktop-background);
  }
  */
}
