.onboarding ion-content {
    max-width: 740px;
    left: 50%;
    transform: translate(-50%, 0);
}

ion-slides {
    height: 100%;
}

ion-slide {
    padding: 0;
}

.body {
    width: 100%;
    height: 100vh;
}

.cover {
    background-image: url(//eatvegly-lon.s3.eu-west-2.amazonaws.com/app-assets/onboarding-bg.svg);
    background-position: center bottom;
    background-repeat: no-repeat;
    display: block;
    height: 50%;
    margin: 0 auto;
    overflow: hidden;
}

.cover img {
    display: block;
    width: auto;
    height: 100%;
    margin: 0 auto;
}

.content {
    display: block;
    padding-top: 24px;
    padding-bottom: 16px;
}

.slide p {
    display: block;
    margin-left: 16px;
    margin-right: 16px;
}

.footerActions, .headerActions {
    display: block;
    position: absolute;
    z-index: 999;
    bottom: 0;
    width: 100%;
}

.headerActions {
    top: 0;
}

.headerActions span {
    display: flex;
    justify-content: flex-end;
}

.footerActions span {
    display: flex;
    justify-content: space-between;
}